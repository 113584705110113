<div class="rs-card px-30px">
  <div class="row align-items-center mt-15px">
    <h4 class="col-3">
      {{selectionTitle | translate}}
    </h4>

    <div class="col">
      <ng-container *ngIf="filteredListOptions?.length; else noListOptions">
        <mat-form-field>
          <mat-select [(ngModel)]="selectedValue">
            <mat-option
              *ngFor="let option of filteredListOptions"
              [matTooltip]="option.tooltip || ''"
              [value]="option.id"
              matTooltipPosition="left"
            >
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>

    <ng-template #noListOptions>
      <div>
        {{ 'NO_DATA_AVAILABLE' | translate }}
      </div>
    </ng-template>

    <div class="col-auto px-15px">
      <button
        (click)="addToList(selectedValue)"
        [disabled]="selectedValue === 0 || disableActions"
        class="rs-btn--primary"
        data-cy="al-add-button"
        mat-button
        type="button"
      >
        <mat-icon>add</mat-icon>
        {{'BUTTON.ADD' | translate}}
      </button>
    </div>
  </div>
  <div class="row my-30px">
    <h4 class="col-3">
      {{listTitle | translate}}
    </h4>
    <div class="col">
      <h4 class="mb-15px rs-color--regent-gray">
        {{listSubtitle | translate}}
      </h4>

      <mat-divider></mat-divider>

      <div
        class="mt-15px"
        data-cy="al-list"
      >

        <div>
          <ng-container *ngIf="sortedListItems?.length; else noListItems">
            <div
              (click)="removeFromList(item)"
              *ngFor="let item of sortedListItems"
              class="row list-item rounded align-items-center"
            >
              <p class="col">
                <span class="rs-color--regent-gray">{{item.prefix}}</span>
                {{item.name}}
                <span class="rs-color--regent-gray">{{item.suffix}}</span>
              </p>
              <div class="px-15px col-auto">
                <button
                  [disabled]="disableActions"
                  mat-icon-button
                >
                  <mat-icon>delete_outlined</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-template #noListItems>
          {{ 'NO_DATA_AVAILABLE' | translate }}
        </ng-template>
      </div>
    </div>
  </div>
</div>